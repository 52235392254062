import React, { useState } from "react";
// import OptionsButton from "../../components/Common/buttons/OptionsButton";
import EwalletTiles from "../../components/Ewallet/EwalletTiles";
import EwalletTable from "../../components/Ewallet/EwalletTable";
import FundTransfer from "../../components/Ewallet/FundTransfer";
import SalesEntry from "../../components/Ewallet/SalesEntry";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SubmitButton from "../../components/Common/buttons/SubmitButton";

const EwalletLayout = () => {
  const [showTransfer, setShowTransfer] = useState(false);
  const [showSales, setShowSales] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  // const style = {
  //   position: "absolute",
  //   inset: "0px auto auto 0px, margin: 0px",
  //   transform: "translate(190px, 42px)",
  // };

  const handleEwalletTransfer = () => {
    setShowTransfer((prevShowTransfer) => !prevShowTransfer);
  };
  const handleSales = () => {
    setShowSales((prevShowSales) => !prevShowSales);
  };
  const ewallet = ApiHook.CallEwalletTiles();
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );

  return (
    <>
      <div className="page_head_top">{t("ewallet")}</div>
      <div className="ewallet_top_btn_sec">
        <div className="ewallet_top_btn_sec">
          <div className="row justify-content-between">
            <div className="col-md-4"></div>
            <div className="col-md-4 text-end ">
              <div className="dropdown btn-group top_right_pop_btn_position">
                <SubmitButton
                  isSubmitting=""
                  click={handleEwalletTransfer}
                  text={"ewallet_fund_transfer"}
                  className="top_righ_pop_btn"
                />
                <SubmitButton
                  isSubmitting=""
                  click={handleSales}
                  text={"Sales_entry"}
                  className="top_righ_pop_btn"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
      <EwalletTiles
        tiles={ewallet?.data}
        currency={userSelectedCurrency}
        conversionFactor={conversionFactor}
      />
      <EwalletTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        currency={userSelectedCurrency}
      />
      <FundTransfer
        balance={ewallet?.data?.balance}
        show={showTransfer}
        handleClose={handleEwalletTransfer}
        currency={userSelectedCurrency}
      />
      <SalesEntry
        show={showSales}
        handleSales={handleSales}
        currency={userSelectedCurrency}
      />
    </>
  );
};

export default EwalletLayout;


// <div className="row justify-content-between">
//   <div className="col-md-4">
//     {!!moduleStatus?.pin_status && (
//       <>
//         <NavLink className="btn_ewallt_page" activeclassname="active">
//           {t("ewallet")}
//         </NavLink>
//         <NavLink to={"/e-pin"} className="btn_ewallt_page">
//           {t("epin")}
//         </NavLink>
//       </>
//     )}
//   </div>
//   <div className="col-md-4 text-end">
//     <OptionsButton
//       title={"ewallet_fund_transfer"}
//       handleOpen={handleEwalletTransfer}
//       style={style}
//       type={"ewallet"}
//     />
//   </div>
// </div>