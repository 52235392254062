import React, { useState } from "react";
import { Offcanvas, Form, Button } from "react-bootstrap";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { PhoneInput } from "react-international-phone";

const SalesEntry = ({ show, handleSales, currency }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    handleSales();
    reset();
  };
 
  const salseMutation = ApiHook.CallSales();


  const onSubmit = async (data) => {
    console.log(data);

    if (data.phoneNumber.length <= 6) {
      setError("phoneNumber", {
        type: "manual",
        message: t("min_length"),
      });
      return;
    }


    await salseMutation.mutateAsync(data, {
      onSuccess: (res) => {
        if (res.data.status) {
          reset()
          handleClose(false);
        } else {
          if (res?.data?.code) {
            toast.error(t(res?.data?.description));
          } else {
            toast.error(t(res?.message));
          }
        }
      },
    });
  };
  return (
    <Offcanvas
      show={show}
      onHide={handleSales}
      placement="end"
      id="SalesEntry"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{t("Sales_entry")}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <Form.Label>
              {t("amount")}
              <span>*</span>
            </Form.Label>
            <div className="amount-field input-group">
              <Form.Control as="select" disabled className="max-40">
                <option value="">{currency?.symbolLeft}</option>
              </Form.Control>
              <Controller
                name="amount"
                control={control}
                defaultValue=""
                rules={{
                  required: t("this_field_is_required"),
                }}
                render={({ field }) => (
                  <>
                    <Form.Control {...field} type="number" />
                    {/* {errors.amount && (
                      <span className="text-danger">
                        {errors.amount.message}
                      </span>
                    )} */}
                  </>
                )}
              />

            </div>
            {errors.amount && (
              <span className="text-danger">
                {errors.amount.message}
              </span>
            )}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>{t("address")}:</Form.Label>
            <Controller
              name="address"
              control={control}
              defaultValue=""
              rules={{
                required: t("this_field_is_required"),
              }}
              render={({ field }) => (
                <>
                  <Form.Control as="textarea" {...field} type="text" />
                  {errors.address && (
                    <span className="text-danger">
                      {errors.address.message}
                    </span>
                  )}
                </>
              )}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>{t("zipCode")}</Form.Label>
            <Controller
              name="zipCode"
              control={control}
              defaultValue=""
              rules={{
                required: t("this_field_is_required"),
                // pattern: {
                //   value: /^[0-9]+$/,
                //   message: t("invalid_format_number"),
                // },
              }}
              render={({ field }) => (
                <>
                  <Form.Control {...field} type="text" />
                  {errors.zipCode && (
                    <span className="text-danger">
                      {errors.zipCode.message}
                    </span>
                  )}
                </>
              )}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>{t("city")}</Form.Label>
            <Controller
              name="city"
              control={control}
              defaultValue=""
              rules={{
                required: t("this_field_is_required"),
                pattern: {
                  value: /^[a-zA-Z0-9\s]+$/,
                  message: t("invalid_format_space"),
                },
              }}
              render={({ field }) => (
                <>
                  <Form.Control {...field} type="text" />
                  {errors.city && (
                    <span className="text-danger">
                      {errors.city.message}
                    </span>
                  )}
                </>
              )}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>{t("phone_number")}</Form.Label>
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue=""
              rules={{
                required: t("this_field_is_required"),
              }}
              render={({ field }) => (
                <>
                  <PhoneInput
                    defaultCountry="us"
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                  {errors.phoneNumber && (
                    <span className="text-danger">
                      {errors.phoneNumber.message}
                    </span>
                  )}
                </>
              )}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t("transactionNumber")} *</Form.Label>
            <Controller
              name="transactionNumber"
              control={control}
              defaultValue=""
              rules={{
                required: t("this_field_is_required"),

              }}
              render={({ field }) => (
                <>
                  <Form.Control {...field} type="text" />
                  {errors.transactionNumber && (
                    <span className="text-danger">
                      {errors.transactionNumber.message}
                    </span>
                  )}
                </>
              )}
            />
          </Form.Group>
          <div>
            <Button variant="primary" type="submit" style={{ width: "100%", height: "40px" }}>
              {t("submit")}
            </Button>
          </div>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SalesEntry;
